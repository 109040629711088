@import url(https://www.contentstack.com/sdks/contentstack-ui-extensions/dist/1.1.0/ui-extension-sdk.css);
.main-Conatiner{display:flex;flex-direction:column;height:95vh;width:99%;margin-left:.5%;justify-content:space-between}.main-Conatiner .next-conatiner{display:flex;flex-direction:column;justify-content:space-between;width:100%;height:30%}.main-Conatiner .next-conatiner .next-input-conatiner{width:24%}.main-Conatiner .next-conatiner .next-button-conatiner{width:30%;margin-bottom:30px}.main-Conatiner .intial-Conatiner{display:flex;flex-direction:column;justify-content:space-between;width:100%;height:30%}.main-Conatiner .intial-Conatiner .select-conatiner{display:flex;flex-direction:row;width:90%;justify-content:space-between}.main-Conatiner .intial-Conatiner .input-conatiner{width:24%}.main-Conatiner .intial-Conatiner .button-conatiner{width:30%;margin-bottom:30px}.main-Conatiner .table-Conatiner{width:100%;height:100%}.main-Conatiner .preview-button{width:30%;margin-left:10px}.main-Conatiner .Table{min-height:20px !important;height:364px}.main-Conatiner .Table .Table__body{height:330px !important}.main-Conatiner .Select .Select__control{width:270px}.main-Conatiner .Select .Select__menu{min-width:260px}.main-Conatiner .Select .Select__menu .Select__menu-list{max-height:100px !important}.main-Conatiner .DateTimePicker{display:flex;flex-direction:column;overflow:scroll;height:30vh !important;width:100%}.main-Conatiner .DateTimePicker .react-datepicker{width:100%}.main-Conatiner .DateTimePicker .TimePicker__time{width:100%}.main-Conatiner .DateTimePicker .Dropdown__menu--primary{min-width:100px !important;width:20px !important;height:100px !important}.main-Conatiner .DateTimePicker .Dropdown__menu--primary .Dropdown__menu__list{width:100px !important;height:100px !important}
.app{display:flex;width:100%;margin-top:7px}.app .layout-container{width:100%;display:flex}.app .layout-container .page-wrapper{display:flex;flex-direction:row;padding:60px 50px}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

