@import url("https://www.contentstack.com/sdks/contentstack-ui-extensions/dist/1.1.0/ui-extension-sdk.css");

.main-Conatiner {
  display: flex;
  flex-direction: column;
  height: 95vh;
  width: 99%;
  margin-left: 0.5%;
  justify-content: space-between;

  .next-conatiner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 30%;

    .next-input-conatiner {
      width: 24%;
    }

    .next-button-conatiner {
      width: 30%;
      margin-bottom: 30px;
    }
  }

  .intial-Conatiner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 30%;

    .select-conatiner {
      display: flex;
      flex-direction: row;
      width: 90%;
      justify-content: space-between;
    }

    .input-conatiner {
      width: 24%;
    }

    .button-conatiner {
      width: 30%;
      margin-bottom: 30px;

    }

  }

  .table-Conatiner {
    width: 100%;
    height: 100%;
  }

  .preview-button {
    width: 30%;
    margin-left: 10px;
  }


  & .Table {
    min-height: 20px !important;
    height: 364px;

    & .Table__body {
      height: 330px !important;
    }
  }

  & .Select {
    & .Select__control {
      width: 270px;
    }

    & .Select__menu {
      min-width: 260px;

      & .Select__menu-list {
        max-height: 100px !important;
      }
    }
  }

  & .DateTimePicker {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: 30vh !important;
    width: 100%;

    & .react-datepicker {
      width: 100%;
    }

    & .TimePicker__time {
      width: 100%;
    }



    & .Dropdown__menu--primary {
      min-width: 100px !important;
      width: 20px !important;
      height: 100px !important;

      & .Dropdown__menu__list {
        width: 100px !important;
        height: 100px !important;
      }

    }

  }

}